import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      category: [],
    }
    this.render_category = this.render_category.bind(this)
  }

  componentDidMount() { 
    let category = [];
    if (this.props.cateogry !== undefined) this.props.category.edges.map((item,key) => {
      const ca = item.node.frontmatter;
      category.push(ca)
    })
    this.setState({
      category: category
    })
  }

  render_category() {
    let category = [];
    this.props.category.edges.map((item,key) => {
      const ca = item.node.frontmatter;
      category.push(ca)
    })
    var _self = this
    if (this.state.category.length === 0 && category.length !== 0) 
      setTimeout(() => {
        this.setState({
          category: category
        })
      },10)
  }

  render() {
    this.render_category();
    return (
      <React.Fragment>
        <div className="footer-container">
          <div className="allright bottom-flex">
            <div className="categories section">
              <h3 className="office-address">Categories</h3>
                <div className="cat-items">
                  {
                    this.state.category.map((item, key) => {
                      return (
                        <Link key={key} to={`/products?category_${item.title}`}>
                          <h3 className="item">{item.title}</h3>
                        </Link>
                      )
                    })
                  }
                </div>
            </div>
            <div className="section">
              <Link to={`/about`}>
                <h3 className="item">{'About Us'}</h3>
              </Link>
              <Link to={`/contact`}>
                <h3 className="item">{'Help & Support'}</h3>
              </Link>
              <Link to={`/support`}>
                <h3 className="item">{'Faq'}</h3>
              </Link>
              <br />
              <Link to={`/terms`}>
                <h3 className="item">{'Terms & Conditions'}</h3>
              </Link>
              <Link to={`/privacy`}>
                <h3 className="item">{'Privacy Policy'}</h3>
              </Link>
              <Link to={`/cookie`}>
                <h3 className="item">{'Cookie Policy'}</h3>
              </Link>
              <Link to={`/wholesale`}>
                <h3 className="item">{'Wholesale'}</h3>
              </Link>
            </div>
            <div className="section">
                <h3>Tel: <span className="office-address">(65) 6908 0812</span></h3>
                <h3>Email: <span className="office-address">sales@x2lab.com.sg</span></h3>
              <h3>Address: <br /><span className="office-address">55 Serangoon North Ave 4, <br />#04-01, S9 <br />Singapore 555859</span></h3>
            </div>
          </div>
          <div className="allright">
            <hr className="border-top--transparent" />
            <div className="top-padding">
              <h3 className="align-center">© 2009 - 2020 X2 Lab Pte Ltd. All Rights Reserved.</h3>
            </div>
          </div>
        </div>
        {/* --- STYLES --- */}
        <style jsx>{`
          .section {
            width: 20%;
            min-width: 200px;
            margin-bottom: 30px;
          }
          .align-center {
            text-align: center
          }
          .bottom-flex {
            flex-wrap: wrap;
            display: flex;
            justify-content: space-evenly;
            padding-bottom: 0px !important;
          }
          .cat-items {
            .item {
              text-transform: capitalize;
            }
          }
          .top-padding {
            padding-top: 30px;
            padding-left: 15px;
            padding-right: 15px;
          } 
          .office-address {
            color: #fff !important;
            font-size: 0.9rem;
          }
          .footer-container {
            .border-top--transparent {
              display: block;
              margin: 0px;
              border-top: 1px solid rgba(255, 255, 255, 0.3);
            }
            background-color: #052b6c;
            .email {
              color: #fff;
            }
            .address {
              color: #fff;
              font-size: 12px;
            }
            .social-section {
              margin-bottom: 1rem;
              display: flex;
              align-items: center;
              margin-right: 3rem;
            }
            .fill-color--white {
              color: #fff;
            }
            h3 {
              font-size: 0.9375rem;
              color: #0098e4;
              margin-right: 2rem;
              font-size: 0.9375rem;
              line-height: 23px;
              line-height: 1.4375rem;
              font-family: Helvetica, Arial, 'Droid Sans', sans-serif;
              font-weight: 500;
            }
            .social {
              margin-right: 2rem;
            }
            .svg {
              fill: #fff;
              width: 1.875rem;
              height: 1.875rem;
            }
            .footer-content {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              max-width: 70em;
              margin: 0 auto;
              padding: 30px;
            }
            .allright {
              max-width: 70em;
              margin: 0 auto;
              padding-top: 30px !important;
              padding-bottom: 50px;
            }
          }
          @from-width desktop {
          }
          @below desktop {
            .footer-container {
              margin-bottom: 70px;
            }
            .footer-content {
              padding: 10px !important;
              padding-left: 10px;
              padding-right: 10px;
              h3 {
                margin-right: 1rem;
              }
            }
          }
        `}</style>
      </React.Fragment>
    )
  }
}

Footer.propTypes = {
  html: PropTypes.string,
  theme: PropTypes.object.isRequired
};

export default Footer;

