import * as actionType from '../actionType';

const loginReducer = (state = {login: 'Login', button: false}, action) => {
    switch(action.type) {
        case actionType.LOGIN: {
            const mem = state
            mem.login = action.value
            return {
                ...state, mem
            };
        }
        case actionType.BUTTON: {
            const mem = state
            mem.button = action.value
            return {
                ...state, mem
            };
        }        
        default: { return state; }
    }
};

export default loginReducer; 
