export const categories = [
    "lithium-ion battery", "fuel cell", "anaerobic fermentation", "solar simulation", "design & customization", "electrodes", "general laboratory items", "AIoT Solutions"
]


export const keywords = [
    "Electrode", "Swagelok-Type Cell", "Nutlock-Type Cell", "Nutlock", 
    "Customized Furniture", "Customization Test Setup",
    "LiO2 Bottle", "LiO2 Coin Cell Cases",
    "Anaerobic Fermentation", "Food Waste Processing System",
    "Solar Simulation", "High-Flux Solar Simulator", "Photocatalytic Reaction",
    "Copper Foil Punch", "Alunimum Foil Punch", "Separator Punch",
     "Coin Cell Case", "SS316 Coin Cell", "CR2032 SS316", "CR2025", "CR2016"
]

export const credential = [
    {
        // new Cryptr('singaporege2020miaomiaomiao');
        // markpro: 226771a0af7f653d1d2f523dd09c4b8b4194a14f7bd3c0
        //whatifthisnots9: 8255dec5c429d9f8799b16d468fc2d8ccd28ae770dc197cabae2857f24dc0b
        username: '226771a0af7f653d1d2f523dd09c4b8b4194a14f7bd3c0',
        password: '8255dec5c429d9f8799b16d468fc2d8ccd28ae770dc197cabae2857f24dc0b',
    }
    
    /*
    {
        // new Cryptr('myTotalySecretKey123');
        // mark, 123456777 
        username: '8284c2fb945bda96f85932981eeb3cf698f0f30c',
        password: '3af74e2c6804c6c0e5f113c7e7c410dd0b18fb53fea6281294',
    }
    */
]

var crypto = require('crypto');

const Cryptr = require('cryptr');
//export const cryptr = new Cryptr('myTotalySecretKey123');
export const cryptr = new Cryptr('singaporege2020miaomiaomiao');