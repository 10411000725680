import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.render_content = this.render_content.bind(this)
  }
  
  render_content() {

    const { theme, selected, item: { label, to, icon: Icon } = {}, onClick } = this.props;
   
    let class_name = "hiddenItem" in this.props ? "hiddenItem" : "item"
    if (selected === true) class_name += " " + "underline"
    if (label === 'Login') {
      return (
        <div></div>
      )
    } else if (label === 'Logout') {
      return (
        <div key={label}>
          <li className={class_name} >
            <a href="#" onClick = {this.props.onLogout}>
              {Icon && <Icon />} {label}
            </a>
          </li>

          {/* --- STYLES --- */}
          <style jsx>{`
            .item,
            .showItem {
              background: transparent;
              transition: all ${theme.time.duration.default};
              display: flex;
              align-items: center;
              :global(a) {
                padding: ${theme.space.inset.s};
              display: flex;
              align-items: center;
              color: #052b6c !important;
              font-weight: 700 !important;
              }

              :global(svg) {
                margin: 0 ${theme.space.inset.xs} 0 0;
                opacity: 0.3;
              }

            }
            .underline {
              border-bottom: 2px solid #0098e4;
              :global(svg) {
                opacity: 1
              }

            }
            :global(.itemList .hideItem) {
              display: none;
            }
            @from-width desktop {
              .item {
                :global(a) {
                  color: ${theme.text.color.primary};
                  padding: ${theme.space.inset.s};
                  transition: all ${theme.time.duration.default};
                  border-radius: ${theme.size.radius.small};
                }
                :global(.homepage):not(.fixed) & :global(a) {
                  color: ${theme.color.neutral.white};
                }
                :global(a:hover) {
                  color: ${theme.color.brand.primary};
                  background: color(white alpha(-60%));
                }
                &:clicked :global(svg) {
                  fill: #0098e4;
                }
                :global(svg) {
                  transition: all ${theme.time.duration.default};
                }
                &:hover :global(svg) {
                  fill: ${theme.color.brand.primary};
                  opacity: 1;
                  
                  :global(.hero) & :global(svg) {
                    fill: green;
                  }
                }           
              }

              .showItem {
                display: none;
              }

              .hiddenItem {
                text-align: left;
                padding: ${theme.space.xs};
                & :global(a.inHiddenItem) {
                  color: ${theme.text.color.primary};
                  &:hover {
                    color: ${theme.color.brand.primary};
                  }
                }
              }
            }
            `}</style>
            </div>
          )
        } else {
          return (
            <div key={label}>
              <li className={class_name} >
                <Link
                  to={to}
                  className={"hiddenItem" in this.props ? "inHiddenItem" : ""}
                  onClick={onClick}
                  data-slug={to}
                >
                  {Icon && <Icon />} {label}
                </Link>
              </li>
    
              {/* --- STYLES --- */}
              <style jsx>{`
                .item,                
            .showItem {
              background: transparent;
              transition: all ${theme.time.duration.default};
              display: flex;
              align-items: center;
              :global(a) {
                padding: ${theme.space.inset.s};
                display: flex;
                align-items: center;
                color: #052b6c !important;
                font-weight: 700 !important;
              }
              :global(svg) {
                margin: 0 ${theme.space.inset.xs} 0 0;
                opacity: 0.3;
              }
            }
            .underline {
              border-bottom: 2px solid #0098e4;
              :global(svg) {
                opacity: 1
              }
            }
            :global(.itemList .hideItem) {              
              display: none;
            }

            @from-width desktop {
              .item {
                :global(a) {
                  color: ${theme.text.color.primary};
                  padding: ${theme.space.inset.s};
                  transition: all ${theme.time.duration.default};
                  border-radius: ${theme.size.radius.small};
                }
                :global(.homepage):not(.fixed) & :global(a) {
                  color: ${theme.color.neutral.white};
                }

                :global(a:hover) {
                  color: ${theme.color.brand.primary};
                  background: color(white alpha(-60%));
                }
                &:clicked :global(svg) {
                  fill: #0098e4;
                }
                :global(svg) {
                  transition: all ${theme.time.duration.default};
                }
                &:hover :global(svg) {
                  fill: ${theme.color.brand.primary};
                  opacity: 1;
                  
                  :global(.hero) & :global(svg) {
                    fill: green;
                  }
                }
              }
              .showItem {
                display: none;
              }
              .hiddenItem {
                text-align: left;
                padding: ${theme.space.xs};
                & :global(a.inHiddenItem) {
                  color: ${theme.text.color.primary};
                  &:hover {
                    color: ${theme.color.brand.primary};
                  }                  
                }
              }
            }
            `}</style>
            </div>
          )
        }
      }
      render () {
    
    
        return (
          <React.Fragment>
            { this.render_content() }
      </React.Fragment>
    );
  }
};

Item.propTypes = {
  item: PropTypes.object,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.func,
  theme: PropTypes.object.isRequired
};

export default Item;
